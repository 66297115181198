import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  cardOfferSection: {
    position: 'relative',
    '& .technologiesIconAws': {
      maxWidth: 90,
      width: 90,
    },
    '& .technologiesIconDigitalOcean': {
      maxWidth: 100,
    },
    '& .technologiesIconHosteur': {
      maxWidth: 150,
    },
    '& .technologiesIconGoogleCloud': {
      maxWidth: 225,
      width: 225,
    },

    '& .technologiesIconAzure': {
      maxWidth: 150,
    },
    '& .MuiList-root': {
      justifyContent: 'space-around',
    },
  },
  background: {
    position: 'absolute',
    top: '9%',
    right: 0,
  },
  cardOffersContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(9),
  },
  cardOffersTitle: {
    '&:after': {
      backgroundColor: '#D34246',
      right: 0,
      left: 'inherit',
    },
    '@media (max-width: 768px)': {
      textAlign: 'left !important',
      '&:after': {
        backgroundColor: '#D34246',
        right: 'inherit',
        left: 0,
      },
    },
  },
  cardOfferWrapper: {
    maxWidth: 1600,
    margin: '0 auto',
    display:'none'
  },
  cardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(15),
    '@media (max-width: 1200px)': {
      paddingTop: 20,
    },
    '@media (max-width: 768px)': {
      paddingTop: 0,
      flexWrap: 'wrap',
    },
  },
  cardOffer: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {},
  },
  subTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '1.75rem',
    marginBottom: '1rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    width: '50%',
    maxWidth: 690,
    // paddingTop: theme.spacing(8),
    // paddingBottom: theme.spacing(8),
    '@media (max-width: 1200px)': {
      paddingTop: 10,
      paddingLeft: 0,
      paddingBottom: 10,
    },
    '@media (max-width: 992px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      order: '2 !important',
    },
    '&:hover>div:last-of-type': {
      opacity: 1,
    },
  },
  cardMedia: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardMedia1: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',

    '@media (max-width: 1280px)': {
      flexBasis: '40%',
    },
    '@media (max-width: 1200px)': {
      flexBasis: '45%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardContent: {
    padding: 0,
  },
  cardTitle: {
    paddingLeft: 0,
    fontSize: '3.75rem',
    fontWeight: 'bold',
    '@media (max-width: 992px)': {
      paddingRight: 0,
      width: '100%',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      margin: 0,
      '& span': {},
      '& > .MuiCardHeader-content': {
        maxWidth: 'fit-content',
      },
    },
    '@media (max-width: 576px)': {},
    '& > .MuiCardHeader-avatar': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 77,
      height: 77,
      border: '1px solid #27272729',
      borderRadius: '50%',
      '@media (min-width: 769px) and (max-width: 991px)': {
        width: 44,
        height: 44,
      },
      '@media (max-width: 576px)': {
        width: 44,
        height: 44,
      },
      '& svg': {
        fontSize: '3rem',
        '@media (min-width: 769px) and (max-width: 991px)': {
          fontSize: '2rem',
        },
        '@media (max-width: 576px)': {
          fontSize: '2rem',
        },
      },
    },
    '& span': {
      lineHeight: 1.2,
      fontWeight: 400,
      textTransform: 'initial',
      fontFamily: 'ubuntu',
      fontSize: '2.125rem',
      '@media (min-width: 1200px) and (max-width: 1600px)': {
        fontSize: '2rem',
      },
      '@media (min-width: 992px) and (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width: 769px) and (max-width: 991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      '& span': {
        fontWeight: 600,
      },
      '& + span': {
        fontWeight: 300,
        fontSize: '1.25rem',
        marginTop: 20,
      },
      '& br': {
        '@media (max-width: 768px)': {
          display: 'none',
        },
      },
    },
  },
  cardTitleTop: {
    '& span': {
      fontSize: '6.5rem',
      fontWeight: 600,
      '@media (max-width: 1599px)': {
        fontSize: '6rem',
      },
      '@media (max-width: 1444px)': {
        fontSize: '5.5rem',
      },
      '@media (max-width: 1200px)': {
        fontSize: '4.5rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '3rem',
      },
      '@media (max-width: 365px)': {
        fontSize: '2.5rem',
      },
    },
  },
  cardText: {
    color: '#808080',
    marginBottom: theme.spacing(2),
    maxWidth: 684,
    '@media (max-width: 992px)': {
      fontSize: '1.125rem',
      marginBottom: 5,
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      textAlign: 'center',
    },
    '@media (max-width: 576px)': {},
    '& span': {
      color: '#E0363A',
      fontWeight: 600,
    },
  },

  rollover: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  btnDemarrer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    borderRadius: 4,
    borderColor: '#272727',
    color: '#272727',
    boxShadow: 'none',
    transition: '50ms ease-in',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media (max-width: 360px)': {
      fontSize: '0.725rem',
    },
    '&:hover': {
      paddingLeft: 20,
      paddingRight: 20,
      transition: '50ms ease-in',
    },
    '& svg': {
      transition: '50ms ease-in',
    },
    '&:hover svg': {
      transition: '50ms ease-in',
      transform: 'translateX(5px)',
    },
  },

  titleWrapperContainer: {
    maxWidth: 1664,
  },

  titleWrapper: {
    position: 'relative',
    paddingTop: 103,
    paddingBottom: 103,
    '@media (max-width: 600px)': {
      paddingTop: 64,
      paddingBottom: 42,
    },
  },

  titleService: {
    color: '#fff',
    '&:after': {
      backgroundColor: '#E0363A',
      right: 0,
    },
  },
  contentBtn: {
    '& p': {
      color: '#808080',
      marginBottom: 16,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
}));
