import React from 'react';

import { Card, CardMedia, Typography, Button, Box } from '@material-ui/core';
import { TransitionLink as Link } from './../../Common/TransitionLink/TransitionLink';
import IconArrowRightAlt from '../../Common/Icons/IconArrowRightAlt';
import IconLogiciel from '../../Common/Icons/IconLogiciel';
import IconSite from '../../Common/Icons/IconSite';
import img1 from '../../../images/mockup_laptop_smartpredict.png';
import img2 from '../../../images/infogerance/img_serverless.svg';
import img3 from '../../../images/infogerance/img_devops.svg';
import Layout from '../Layout';

import classnames from 'classnames';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  title: string;
  subTitle?: string;
  description?: string;
  image?: string;
}

const isBrowser = typeof window !== 'undefined';
{
  /*const services2 = [
  {
    title: 'SmartPredict',
    subTitle: 'Courte description du projet',
    description: [
      'SmartPredict est un logiciel SaaS destiné aux entreprises et qui leur permet de créer et déployer des projets d’intelligence artificielle en rapport avec leurs métiers. Les usages sont multiples sur tous les secteurs : prévisions de consommation (industrie), recommandations de produits (marketing), prédictions de défaut (banque et assurance), etc. Avec leur dernier outil RetailForecastor, l’entreprise permet aux entreprises de la grande distribution de prédire leurs ventes et optimiser leurs activités en évitant la surproduction ou la sous-production.',
    ],
    image: img1,
  },
];*/
}

const BlocHeaderTemoignage = ({
  title,
  subTitle,
  description,
  image,
}: PropTypes) => {
  const classes = styles();
  return (
    <Box id="headerBlocTemoignage">
      <Box id="cardOffers" className={classes.cardOfferSection}>
        <Layout className={classes.cardOffersContainer}>
          <Box className={classes.cardOfferWrapper}>
            <Card className={classnames(classes.cardRoot)}>
              <CardMedia
                classes={{ root: classes.cardMedia }}
                image={image}
                title="card media"
              />
              <Box className={classes.card}>
                <Typography component="h1" className={classes.cardTitle}>
                  {ReactHtmlParser(title)}
                </Typography>

                <Typography component="h2" className={classes.subTitle}>
                  {subTitle}
                </Typography>
                <Box className={classes.rollover}>
                  <Typography
                    className={classnames('textParagraph', classes.cardText)}
                  >
                    {description}
                  </Typography>

                  <Box mt={2} className={classes.contentBtn}>
                    <Typography>
                      Voulez vous en savoir plus sur ce projet ?
                    </Typography>
                    <Link
                      to="/contact"
                      state={{
                        prevUrl: isBrowser ? window.location.pathname : '',
                      }}
                    >
                      <Button
                        className={classnames(
                          'classicButton',
                          classes.btnDemarrer,
                        )}
                        variant="outlined"
                        endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
                      >
                        Contactez-nous
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>
        </Layout>
      </Box>
    </Box>
  );
};
export default BlocHeaderTemoignage;
