import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    paddingLeft: 40,
    paddingRight: 40,
  },
  containerWrapper: {
    margin: '24px auto 0',
    maxWidth: 1600,
    paddingLeft: 0,
    paddingRight: 0,
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  title: {
    color: '#272727',
    marginRight: 16,
    '&:after': {
      backgroundColor: '#D34246',
    },
  },
  description: {
    maxWidth: 768,
    textAlign: 'right',
    '& p': {
      fontSize: '1.5rem',
      color: '#272727',
      '@media (max-width: 1200px)': {
        fontSize: '1.25rem',
      },
    },
    '@media (max-width: 1440px)': {
      maxWidth: 500,
    },
    '@media (max-width: 1200px)': {
      maxWidth: 400,
    },
    '@media (max-width: 900px)': {
      maxWidth: '100%',
      textAlign: 'left',
    },
  },
  temoignagesList: {
    marginTop: '82px',
    marginBottom: '78px',
  },
  contentImg: {
    padding: '0 16px',
    '& img': {
      margin: '0 auto',
      borderRadius: 8,
      maxHeight: 309,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
}));
