import React from 'react';

import { Typography, Box } from '@material-ui/core';
import Layout from '../Layout';

import classnames from 'classnames';

import styles from './style';
import SlickSlider from './../SlickSlider';

import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  title: string;
  description?: string;
  items: any;
}

const BlocHeaderTemoignage = ({ title, description, items }: PropTypes) => {
  const classes = styles();
  const slickSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box id="headerBlocTemoignage" className={classes.root}>
      <Layout className={classes.containerWrapper}>
        <Box className={classes.contentHeader}>
          <Typography
            variant="h2"
            className={classnames('titreSection', classes.title)}
          >
            {ReactHtmlParser(title)}
          </Typography>

          <Box className={classes.description}>
            <Typography className={classnames('textParagraph')}>
              {description}
            </Typography>
          </Box>
        </Box>
        <Box>
          <SlickSlider
            settings={slickSettings}
            classesnames={classes.temoignagesList}
          >
            {items.map(({ id, images }: any) => {
              return (
                <Box className={classes.contentImg} key={id}>
                  <img src={images} alt="liste images" />
                </Box>
              );
            })}
          </SlickSlider>
        </Box>
      </Layout>
    </Box>
  );
};
export default BlocHeaderTemoignage;
